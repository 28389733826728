
          @use "sass:math";
          @import "~@/assets/scss/variables.scss";
          @import "~@/assets/scss/mixins/misc.scss";
        

.setting-item {
  &__child-settings {
    display: flex;
  }
  &__child-setting:not(:last-child) {
    margin-right: 10px;
  }
}
